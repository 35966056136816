import React from "react";

import GridRow from "../../commons/GridRow";
import Button from "../../commons/Button";
import Heading from "../../commons/Heading";
import RichTextRenderer from "../../commons/RichTextRenderer";

import { gradientBg } from "../../../utils/styles";
import { linkResolver } from "../../../utils/links";

function getLayoutStyle(style) {
  switch (style) {
    case "gradient":
      return {
        ...gradientBg,
        "a:hover": {
          color: "black",
        },
      };
    case "black":
    default:
      return {
        backgroundColor: "black",
      };
  }
}

export default function TextBlock({
  title,
  _rawText,
  buttons,
  style,
  ...otherProps
}) {
  return (
    <GridRow
      as="section"
      sx={{
        py: [5, "gridGap"],
        textAlign: ["center", "start"],
        color: "inverted",
        ...getLayoutStyle(style),
      }}
    >
      <GridRow.Col gridColumn={["start / end", "start / span 6"]}>
        <Heading
          variant="h2"
          sx={{
            px: [2, 0],
            mt: [4, 0],
            mb: [4, 0],
          }}
        >
          {title}
        </Heading>
      </GridRow.Col>
      <GridRow.Col
        gridColumn={["start / end", "8 / end"]}
        variant="styles.default"
      >
        <Heading
          as="div"
          variant="h3"
          sx={{
            color: "inverted",
            fontSize: 5,
          }}
        >
          <RichTextRenderer blocks={_rawText} />
        </Heading>
        {buttons.map(linkResolver).map((link) => (
          <Button key={link.to || link.href} {...link} />
        ))}
      </GridRow.Col>
    </GridRow>
  );
}
